import { useEffect, useState } from "react";
import axios from 'axios';
import tseLogo from '../assets/tse.png';

export default function Padron () {
    const [id, setId] = useState('');
    const [listObj, setListObj] = useState([]);
    const [ready, setReady] = useState(false);
    const [listDist, setListDist] = useState([]);
    const [code, setCode] = useState('');
    // PERSONA
    const [cedula, setCedula] = useState('');
    const [nombre, setNombre] = useState('');
    const [apellidos, setApellidos] = useState('');
    const [provincia, setProvincia] = useState('');
    const [canton, setCanton] = useState('');
    const [distrito, setDistrito] = useState('');
    // DEBUGGING
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
  
    const handleFile = (event) => {
      event.preventDefault();
      var file = event.target.files[0];
  
      var extension = event.target.files[0].name.split('.').pop().toLowerCase();
  
      var reader = new FileReader();
      reader.onload = function(event) {
        var res = event.target.result;
  
        // console.log(res);
  
        // console.log(res.split("\r\n"));
  
        var arrayOfObjects = [];
        res.split("\r\n").forEach(element => {
  
          // console.log(element);
  
          var splitted = element.split(',');
          var ced = splitted[0];
          var nombre = splitted[5];
          var apellido1 = splitted[6];
          var apellido2 = splitted[7];
  
          var distelec = splitted[1];
  
          arrayOfObjects.push({
            cedula: ced,
            nombre: nombre,
            apellido1: apellido1,
            apellido2: apellido2,
            distelec: distelec
          })
        });
  
        console.log(arrayOfObjects);
    
        setListObj(arrayOfObjects);
  
        setReady(true);
  
        };
      
        reader.readAsText(file);
  
    }
  
    const handleFileDistelec = (event) => {
      event.preventDefault();
      var file = event.target.files[0];
  
      var reader = new FileReader();
      reader.onload = function(event) {
        var res = event.target.result;
  
        var arrayOfObjects = [];
        res.split("\n").forEach(element => {
  
          var splitted = element.split(',');
          var distelec = splitted[0];
          var provincia2 = splitted[1];
          var canton2 = splitted[2];
          var distrito2 = splitted[3];
  
          arrayOfObjects.push({
            distelec: distelec,
            provincia: provincia2,
            canton: canton2,
            distrito: distrito2,
          })
        });
  
        console.log(arrayOfObjects);
    
        setListDist(arrayOfObjects);
  
        setReady(true);
  
        };
      
        reader.readAsText(file);
  
    }
  
    function checkAge(age) {
      return age.cedula === id;
    }
  
  
    function checkDist(dist, codigo) {
      return dist.distelec === codigo;
    }
  
    const search = () => {
      console.log(listObj);
      var obj = listObj.find(checkAge);
      setCedula(obj.cedula);
      setNombre(obj.nombre);
      setApellidos(obj.apellido1 + " " + obj.apellido2);
  
      var objLugar = listDist.find(ele => ele.distelec === obj.distelec);
  
      setProvincia(objLugar.provincia);
      setCanton(objLugar.canton);
      setDistrito(objLugar.distrito);
  
    }
  
    const getSearch = () => {
      setErrorMessage('');
      setLoading(true);
      //var url = 'https://padron.riffraff.digital/';
      // var url = 'http://localhost:8080/';
      // var url = 'https://padron.amubri.voto/';
      var url = 'https://padron.anep.app/';
      axios.get(url, {
        params: {
          cedula: id
        }
      })
      .then(function (response) {
        var data = response.data;
        if (!data) {
          setErrorMessage('No se encontraron resultados');
        }
        setCedula(data.cedula);
        setNombre(data.nombre.replace('\ufffd', 'Ñ'));
        setApellidos(data.apellido1.replace('\ufffd', 'Ñ') + ' ' + data.apellido2.replace('\ufffd', 'Ñ'));
        setProvincia(data.provincia);
        setCanton(data.canton);
        setDistrito(data.distrito);
        console.log(response.data);
        setLoading(false);
  
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      })
      .then(function () {
        // always executed
      }); 
    }
  
    
  
    return(
      <div className="page" style={{display: 'flex', flexDirection: 'column', gap: '1rem', width: '600px', margin: ' 4rem auto'}}>
        <h1>Padron <img src={tseLogo} width="64px"/></h1>
        <p>A continuación ingresa un número de cédula nacional y obtén la información personal relacionada a la persona. La información es extraída del padrón nacional del TSE.</p>
  
          {/* <input style={{display: 'none'}} type="file" id="files" name="file" onChange={handleFile}/>
          <button className='btn-tonal' onClick={() => {document.getElementById('files').click();}}><span className="material-icons-round">groups</span>Padron nacional</button>
  
          <input style={{display: 'none'}} type="file" id="distelec" name="distelec" onChange={handleFileDistelec}/>
          <button className='btn-tonal' onClick={() => {document.getElementById('distelec').click();}}><span className="material-icons-round">place</span>Distelec</button> */}
  
          <div className='input-container'>
            <input className='input-normal' id="tse-id" type="text" value={id} onChange={e => {setId(e.target.value)}}/>
            <label className='input-label' htmlFor='tse-id'>ID</label>

          </div>
  
          <button className='filled-button' onClick={getSearch}>Buscar por cedula</button>
  
          <p style={{color: 'red'}}>{errorMessage}</p>
  
          {
            loading ?
            <h4 style={{color: 'var(--primary)'}}>Consultando...</h4> :
            null
          }
  
          <h3>{'Cedula: ' + cedula}</h3>
          <h3>{'Nombre: ' + nombre}</h3>
          <h3>{'Apellidos: ' + apellidos}</h3>
          <h3>{'Provincia: ' + provincia}</h3>
          <h3>{'Canton: ' + canton}</h3>
          <h3>{'Distrito: ' + distrito}</h3>
  
      </div>
    );
  }