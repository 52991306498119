import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFirestore, collection, addDoc, getDocs, query, where, orderBy, limit, startAfter, getDoc, doc, serverTimestamp, Timestamp } from "firebase/firestore";
import xlsx from '../assets/xlsx.svg';

const db = getFirestore();

export default function ReporteFinanciero() {
  const [dataList, setDataList] = useState([]);
  const [listaInstituciones, setListaInstituciones] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [institucion, setInstitucion] = useState('');
  const [loading, setLoading] = useState(false);
  // A Y A 
  const [month, setMonth] = useState('2023-05');

  const [cuerda, setCuerda] = useState('Inicial');

  function saveFile(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0)
    }
  }

  function saveSomeData(string) {
    const blob = new Blob([string], {
      type: 'application/json'
    });
    saveFile(blob, "Reporte financiero.csv")
  }

  useEffect(() => {
    console.log(cuerda);
  }, [cuerda])

  useEffect(() => {

    console.log(dataList)

  }, [dataList])

  var list = dataList.map(data =>
    <tbody key={data.key}>
      <tr>
        <td>{data.id}</td>
        <td>{data.name}</td>
        <td>{data.lastname}</td>
        <td>{data.institucion}</td>
        <td>{data.dependencia}</td>
        <td>{data.fechaAfiliacion}</td>
        <td>{data.fechaDesafiliacion}</td>
      </tr>
    </tbody>
  );

  useEffect(() => {
    async function fetchData() {
      const docRef = doc(db, "instituciones", "lista");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data().instituciones);
        setListaInstituciones(docSnap.data().instituciones.sort());
      } else {
        console.log("No such document!");
      }
    }
    fetchData();
  }, []);

  const instList = listaInstituciones.map(data =>
    <option key={data} value={data}>{data}</option>
  )


  const getReport2 = async () => {
    setLoading(true);

    console.log(month);
    let start = new Date(`${month}-01`);
    // Get end date
    let refDate = new Date(`${month}-01`);
    let end = new Date(refDate.setMonth(refDate.getMonth() + 1));

    const pagosRef = collection(db, "pagos");
    const q = query(pagosRef, where("emitido", ">", start), where("emitido", "<", end));

    const querySnapshot = await getDocs(q);
    let pagos = [];
    querySnapshot.forEach((doc) => {
      var obj = {
        emitido: new Date(doc.data().emitido.seconds * 1000).toLocaleDateString(),
        id: doc.data().id,
        monto: doc.data().monto,
        vigencia: new Date(doc.data().vigencia.seconds * 1000).toLocaleDateString(),
        periodo: doc.id.slice(-10)
      }
      pagos.push(obj);
    });
    console.log(pagos);

    const userRef = collection(db, "usuarios");
    const que = query(userRef, where("institucion", "==", searchValue));

    let querySnapshot2;

    if (searchValue) {
      console.log(searchValue);
      querySnapshot2 = await getDocs(que);
    } else {
      querySnapshot2 = await getDocs(userRef);
    }

    let usuarios = [];
    querySnapshot2.forEach((doc) => {
      var obj = {
        id: doc.data().cedula,
        'Cedula': doc.data().cedula,
        'Nombre': doc.data().nombre + ' ' + doc.data().apellidos,
        'Institucion': doc.data().institucion,
        'Fecha Afiliacion': doc.data().ingreso ? new Date(doc.data().ingreso.seconds * 1000).toLocaleDateString() : '',
        'Fecha Desafiliacion': doc.data().desafiliacion ? new Date(doc.data().desafiliacion.seconds * 1000).toLocaleDateString() : '',
        'Estado': doc.data().active ? 'ACTIVO' : 'INACTIVO',
        'Vigencia (Expira)': doc.data().expira ? new Date(doc.data().expira.seconds * 1000).toLocaleDateString() : '',
      }
      usuarios.push(obj)
    });
    console.log(usuarios)

    // Primero, determinamos todos los periodos únicos en los datos de pago.
    let periodos = [...new Set(pagos.map(pago => pago.periodo))];

    // Luego, para cada usuario, buscamos los pagos que correspondan a cada periodo.
    let datosCombinados = usuarios.map(usuario => {
      let pagosUsuario = pagos.filter(pago => pago.id === usuario.id);
      let fila = { ...usuario };
      for (let periodo of periodos) {
        let pagoPeriodo = pagosUsuario.find(pago => pago.periodo === periodo);
        fila[periodo] = pagoPeriodo ? pagoPeriodo.monto : '';
      }
      return fila;
    });

    function aCSV(datos) {
      let header = ["Cedula", "Nombre", "Institucion", "Fecha Afiliacion", "Fecha Desafiliacion", "Estado", "Vigencia (Expira)", ...periodos];
      let csv = datos.map(row => header.map(fieldName => row[fieldName]).join(";")).join("\n");
      return header.join(";") + "\n" + csv;
    }

    let csv = aCSV(datosCombinados);
    saveSomeData(csv);
    setLoading(false);
  }

  return (
    <div className='page'>
      <h1>Reporte financiero</h1>
      <p style={{marginBottom: '1rem'}}>A continuación puedes generar informes financieros generales. Selecciona un mes y el archivo se descargará en formato CSV. Si no selecciona una institucion, se creara un reporte general.</p>

      {/* <div style={{ display: 'flex', flexDirection: 'column', margin: '1rem 0' }}>
        <label htmlFor="select-institucion">Institucion:</label>
        <select id="select-institucion" value={searchValue} onChange={e => setSearchValue(e.target.value)} style={{ width: '200px' }}>
          <option value="" disabled>---Selecciona una Institucion---</option>
          {instList}
        </select>
      </div> */}

      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
        {/* <label htmlFor="select-institucion">Institucion:</label> */}
        <select id="select-institucion" value={searchValue} onChange={e => setSearchValue(e.target.value)} style={{ width: '206px' }}>
          <option value="" disabled>---Selecciona una Institucion---</option>
          {instList}
        </select>
      </div>

      <div style={{ margin: '1rem 0', gap: '1rem', display: 'flex' }}>
        {/* <label htmlFor='mes'>Mes</label> */}
        <div>
          <input className='input-normal' type="month" id="mes" name="mes" value={month} onChange={e => setMonth(e.target.value)}></input>
        </div>
      </div>

      <button className='filled-button' onClick={getReport2} disabled={loading}>Obtener pagos en rango de mes</button>

      {/* <button className='filled-button' onClick={consultardos}>Consultar</button> */}

      {/* <hr style={{ margin: '1rem 0' }}></hr>
      <button className='filled-button' onClick={consultarGeneral1}>Consultar todos los pagos</button>
      <hr style={{ margin: '1rem 0' }}></hr> */}

      {/* <button style={{ margin: '1rem 0' }} className='filled-tonal-button' onClick={generate}><img src={xlsx} height="24px" style={{ marginRight: '0.5rem' }} />Descargar CSV</button> */}


      {loading && <h3 style={{ marginTop: '1rem' }}>Procesando, por favor espere...</h3>}

      {
        loading &&
        <img src={'https://www.downgraf.com/wp-content/uploads/2014/09/01-progress.gif?e44397'} style={{ height: '96px', width: '96px' }} />
      }


      {/* <h4>Vista previa</h4>

      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Institucion</th>
            <th>Dependencia</th>
            <th>Fecha Afiliacion</th>
            <th>Fecha Desafiliacion</th>
          </tr>
        </thead>
        {list}
      </table> */}

    </div>
  );
}