import { collection, getDocs, getFirestore, orderBy, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import './HistorialPago.css';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const db = getFirestore();

export default function HistorialPago() {
    const [paymentList, setPaymentList] = useState([]);

    let queryNav = useQuery();
    const ced = queryNav.get("id");

    useEffect(() => {

        async function fetchData() {
            const citiesRef = collection(db, "pagos");

            const q = query(citiesRef, where("id", "==", ced), orderBy("vigencia", 'desc'));

            const querySnapshot = await getDocs(q);
            var arr = [];
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                var unix = doc.data().vigencia.seconds * 1000;
                var d = new Date(unix);
                console.log(d.toLocaleDateString());
                arr.push({
                    key: doc.id,
                    id: doc.data().id,
                    vigencia: d.toLocaleDateString(),
                    monto: doc.data().monto
                });
            });
            setPaymentList(arr);
        }

        fetchData();

    }, []);

    const list = paymentList.map(data =>
        <div key={data.key} className="payment-ticket">
            <p>Cedula</p>
            <h4>{data.id}</h4>
            <p>Monto</p>
            <h4>{data.monto}</h4>
            <div className="payment-ticket-vigencia">
                <p>Vigencia</p>
                <h4>{data.vigencia}</h4>
            </div>
        </div>
    );

    return (
        <div className="page">
            <h1>Historial de pagos</h1>

            <div style={{ overflowX: 'auto', marginTop: '1rem' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Cedula</th>
                            <th>Monto</th>
                            <th>Vigencia</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            paymentList.map(data =>
                                <tr key={data.key} className="payment-ticket">
                                    <td>{data.id}</td>
                                    <td>{data.monto}</td>
                                    <td>{data.vigencia}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>

            {
                paymentList.length === 0 &&
                    <p>No se encontro ningun pago</p>
            }
        </div>
    );
}