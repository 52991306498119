import { addDoc, collection, doc, getFirestore, setDoc, Timestamp, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import csv from '../assets/csv.svg';
import {
  SearchRegular
} from "@fluentui/react-icons";

const db = getFirestore();

export default function SubirPagos() {
  const [text, setText] = useState('');
  const [listObj, setListObj] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [fechaEmision, setFechaEmision] = useState('');

  let navigate = useNavigate();

  useEffect(() => { document.getElementById('file-submit-btn').disabled = true; }, []);

  const csvToArray = (str, delimiter = ";") => {
    const headers = str.slice(0, str.indexOf("\r\n")).split(delimiter);
    const rows = str.slice(str.indexOf("\n") + 1).split("\r\n");

    const arr = rows.map(function (row) {
      const values = row.split(delimiter);
      const el = headers.reduce(function (object, header, index) {
        object[header] = values[index];
        return object;
      }, {});
      return el;
    });

    return arr;
  }

  function convertirEnArray(str) {
    let arrays = str.split("\r\n").map(function (row) {
      return row.split(";");
    });
    let headers = arrays.shift();
    console.log(arrays);
    console.log(headers);
    let objects = arrays.map(array => {
      let obj = {};
      headers.forEach((header, i) => {
        let value = array[i];
        obj[header] = value;
      });
      return obj;
    });

    console.log(objects);

    return objects;
  }

  const handleFile = (event) => {
    event.preventDefault();
    var file = event.target.files[0];
    setErrorMsg('');

    var extension = event.target.files[0].name.split('.').pop().toLowerCase();

    if (extension === 'csv') {
      var reader = new FileReader();
      reader.onload = function (event) {
        var res = event.target.result;
        var convertido = convertirEnArray(res);
        setListObj(convertido);
        // var convertedRes = csvToArray(res);
        // console.log(convertedRes);
        document.getElementById('file-submit-btn').disabled = false;
      };

      reader.readAsText(file);
    } else {
      setErrorMsg('Formato de archivo incompatible: ' + extension);
    }


  }

  const uploadToFirebase = () => {
    const emitidoToDate = new Date(fechaEmision + 'T00:00:00');
    // console.log(emitidoToDate);
    console.log(Timestamp.fromDate(emitidoToDate));

    const expiraToDate = new Date(emitidoToDate);
    expiraToDate.setDate(expiraToDate.getDate() + 45);
    console.log(Timestamp.fromDate(expiraToDate));


    listObj.forEach(async element => {
      console.log(element.id);
      const userRef = doc(db, "usuarios", element.id);

      try {
        await updateDoc(userRef, {
          expira: Timestamp.fromDate(expiraToDate)
        });
        // await addDoc(collection(db, "pagos"), {
        //   id: element.id,
        //   emitido: Timestamp.fromDate(emitidoToDate),
        //   vigencia: Timestamp.fromDate(expiraToDate),
        //   monto: element.monto
        // });
        await setDoc(doc(db, "pagos", `${element.id}${fechaEmision}`), {
          id: element.id,
          emitido: Timestamp.fromDate(emitidoToDate),
          vigencia: Timestamp.fromDate(expiraToDate),
          monto: element.monto
        });
        console.log(`${element.id} : Documento creado con exito: ${element.id}${fechaEmision}`);
        const pRow = document.createElement('code');
        pRow.innerHTML = `${element.id} : Documento creado con exito: ${element.id}${fechaEmision}`;
        document.getElementById('logs').appendChild(pRow);
      } catch (error) {
        console.log(error.message);
        const pRow = document.createElement('code');
        pRow.innerHTML = error.message;
        document.getElementById('logs').appendChild(pRow);
      }

    });

  }

  // const uploadToFirebase = () => {

  //   listObj.forEach(async element => {
  //     console.log(element.id);
  //     const userRef = doc(db, "usuarios", element.id);

  //     var fecha = element.expira.split("/");
  //     var dateUSA = fecha[1] + '/' + fecha[0] + '/' + fecha[2]
  //     var d = new Date(dateUSA);

  //     var fecha2 = element.emitido.split("/");
  //     var dateUSA2 = fecha2[1] + '/' + fecha2[0] + '/' + fecha2[2]
  //     var d2 = new Date(dateUSA2);

  //     try {
  //       await updateDoc(userRef, {
  //         expira: Timestamp.fromDate(d)
  //       });
  //       await addDoc(collection(db, "pagos"), {
  //         id: element.id,
  //         emitido: Timestamp.fromDate(d2),
  //         vigencia: Timestamp.fromDate(d),
  //         monto: element.monto
  //       });
  //       const pRow = document.createElement('code');
  //       pRow.innerHTML = element.id + ': Documento creado con exito';
  //       document.getElementById('logs').appendChild(pRow);
  //     } catch (error) {
  //       console.log(error);
  //       const pRow = document.createElement('code');
  //       pRow.innerHTML = error.message;
  //       document.getElementById('logs').appendChild(pRow);
  //     }

  //   });

  //   // navigate('/pagos/exito');

  // }

  return (
    <div className="page">
      <h1>Actualiza datos con CSV</h1>
      <input style={{ display: 'none' }} type="file" id="files" name="file" onChange={handleFile} />

      <p>Para actualizar los datos financieros se deberá cargar un archivo CSV de la siguiente manera, separado por ";" con el formato especificado a continuación: Id: Representa la cédula o identificación del afiliado. Expira: La fecha de vigencia del pago se definira automaticamente 45 dias despues de la fecha de emitido para considerarse "No moroso". Monto: Número entero o con decimales. Emitido: Fecha del periodo el cuál corresponde el pago.</p>



      <h4 style={{ margin: '1rem 0' }}>Ejemplo:</h4>

      <table>
        <thead>
          <tr>
            <th>id</th>
            <th>monto</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>123456789</td>
            <td>5000</td>
          </tr>
          <tr>
            <td>987654321</td>
            <td>2000</td>
          </tr>
          <tr>
            <td>123654789</td>
            <td>1000</td>
          </tr>
        </tbody>
      </table>

      <ol style={{ margin: '2rem 0' }}>
        <li>Generar CSV</li>
        <li>Cargar con herramienta a continuacion</li>
        <li>Como buena practica elige unicamente las fechas 1, 8, 15 y 22 de cada mes.</li>
        <li>Haz click en "Cargar"</li>
      </ol>

      <div className='input-container' style={{ width: '360px' }}>
        <input className='input-normal' id="profile-celular" type="date" value={fechaEmision} onChange={e => { setFechaEmision(e.target.value) }} />
        <label className='input-label' htmlFor='profile-celular'>Fecha de emitido</label>
      </div>

      <p style={{ color: 'var(--red)', marginBottom: '1rem' }}>{errorMsg}</p>


      <button className='filled-tonal-button' onClick={() => { document.getElementById('files').click(); }}><SearchRegular fontSize={24} />Elige un archivo .csv <img src={csv} height='24px' style={{ marginLeft: '.5rem' }} /></button>


      {
        listObj.length > 0 && <h3 style={{ marginTop: '2rem' }}>Tamaño de lista: {listObj.length}</h3>
      }

      <button style={{ marginTop: '.5rem' }} id="file-submit-btn" className='filled-button' onClick={uploadToFirebase}>Cargar</button>

      <div id='logs'>
        <h4>Logs:</h4>
      </div>

    </div>
  );
}

